define(['$console', 'app'], ($console, app) => {

  const freeProductSelection = () => {
    const component = {};
    let observer;

    const _config = {
      attrib: {
        skuID: 'data-sku',
        offerID: 'data-offer',
        tierID: 'data-tier',
        masterID: 'data-master-sku',
        skuRemoveID: 'data-remove-sku',
        offerRemoveID: 'data-remove-offer',
        tierRemoveID: 'data-remove-tier',
        freeGiftID: 'data-free-gift-id',
        variationID: 'data-variation-id',
        variationMasterSKU: 'data-master-id',
        optionDetails: 'data-option-details'
      },
      selectors: {
        itemContainer: '[data-js-element=freeProductSelection_itemContainer]',
        freeProductItemProductBlock: '[data-js-element=freeProductSelection_itemProductBlock]',
        freeProductItemProductBlockComplex: '[data-js-element=freeProductSelection_itemProductBlock_complex]',
        freeProductItem: '[data-product-item]',
        selectedFreeProducts: '[data-current-selected]',
        maxFreeProductsElement: '[data-max-selected]',
        totalSelected: '[data-total-selected-tiers]',
        freeGiftBasketItem: '[data-js-element*=Basket_basket_freeGift]',
        basketHeader: '[data-js-element*=Basket_headerContainer]',
        removeItem: '[data-js-element*=Basket_removeItem]',
        addGiftError: '[data-js-element*=Basket_addGiftError]',
        removeGiftError: '[data-js-element*=Basket_removeGiftError]',
        oosProduct: '[data-js-element*=Basket_oosProduct]',
        noMoreOffer: '[data-js-element*=Basket_noOffer]',
        offerExpired: '[data-js-element*=Basket_offerExpired]',
        productOverMaxPerOrder: '[data-js-element*=Basket_productOverMaxPerOrder]',
        optionsDetail: '[data-js-element=freeProductSelection_optionDetails]',
        optionsDetailDesc: '[data-option-desc]',
        complexSelectText: '[data-complex-select]',
        image: '[data-free-gift-image]',
        variationDropdown: '.freeProductSelection_productVariationReplace',
      },
      classNames: {
        freeProductItemSelected: 'freeProductSelection_itemProductBlock_selected',
        freeProductItemSelectedDisabled: 'freeProductSelection_itemProductBlock-disabled',
        freeProductItemSelectedEnabled: 'freeProductSelection_itemProductBlock-enabled',
        basketHeaderDone: 'responsiveBasket_headerContainer-done',
        basketItemRemoving: 'responsiveBasket_body-removing',
        basketItemRemovingOpacity: 'responsiveBasket_body-removingFade',
        showErrorPopup: 'responsiveBasket_error-show',
        complexItemShow: 'freeProductSelection_item-show',
        optionTextShow: 'freeProductSelection_option-show',
        optionTextHide: 'freeProductSelection_option-hidden',
        hasComplexClass: 'freeProductSelection_itemProductBlock_complex',
        variationDropdownShow: 'freeProductSelection_productVariationReplace-show',
      },
      variationOption: {
        variationContainer: '[data-productVariation]',
        variationReplace: '[data-variation-replace=productVariations]',
        dropdownVariation: '[data-dropdown=productVariation]'
      }
    };

    const _init = element => {
      component.element = element;
      if (!component.element || component.element instanceof HTMLElement === false) {
        $console.error('freeProductTiers.init was called without the expected HTMLElement');
        return;
      }

      component.getSelectedElement(element);
      component.freeGiftID = component.element.getAttribute(component.config.attrib.freeGiftID);
      component.isComplexItem = false;
      component.isSimpleItem = true;
      component.radioBehaviour = false;

      if (component.selectedItem) {
        component.lastOfferSelected = {
          skuId: component.selectedItem.getAttribute(component.config.attrib.skuID),
          masterId: component.selectedItem.getAttribute(component.config.attrib.masterID),
          offerId: component.selectedItem.getAttribute(component.config.attrib.offerID),
          tierId: component.selectedItem.getAttribute(component.config.attrib.tierID),
        };
      }

      component.attachListeners();
      component.greyOutMaxedFilter();
      component.observeSelected();

      if (!component.productBlocksComplex.length) {
        return;
      }

      component.alreadySelected();
      component.complexAttachListeners();

      return component;
    };

    const _getSelectedElement = (element) => {
      component.productBlocksComplex = element.querySelectorAll(component.config.selectors.freeProductItemProductBlockComplex);
      component.productBlocks = element.querySelectorAll(component.config.selectors.freeProductItemProductBlock);
      component.productItemCommon = element.querySelectorAll(component.config.selectors.freeProductItem);
      component.selectedItem = element.querySelector(`.${component.config.classNames.freeProductItemSelected}`);
      component.basketHeader = document.querySelector(component.config.selectors.basketHeader);
      component.freeGiftContainer = document.querySelector(component.config.selectors.freeGiftBasketItem);
      component.selectedFreeProductsElement = element.querySelector(component.config.selectors.selectedFreeProducts);
      component.maxFreeProductsElement = element.querySelector(component.config.selectors.maxFreeProductsElement);

      if (component.selectedFreeProductsElement) {
        component.element.selectedFreeProducts = Number(component.selectedFreeProductsElement.innerHTML);
      }

      if (component.maxFreeProductsElement) {
        component.element.maxFreeProducts = Number(component.maxFreeProductsElement.innerHTML);
      }
    };

    const _observeSelected = () => {
      if (!component.selectedFreeProductsElement) return;
      observer = new MutationObserver(component.greyOutMaxedFilter);
      observer.observe(component.selectedFreeProductsElement, {
        attributes: true,
        attributeFilter: ['data-current-selected']
      });
    };

    const _bindNewRemove = (sku, offer, tier) => {
      const newRemoveItem = document.getElementById(sku + offer + tier);
      const newRemoveItemBtn = newRemoveItem.querySelector(component.config.selectors.removeItem);

      newRemoveItemBtn.addEventListener('click', component.removeItem, false);
    };

    const _removeItem = (e) => {
      e.preventDefault();
      const skuRemoveID = e.currentTarget.getAttribute(component.config.attrib.skuRemoveID);
      const offerRemoveID = e.currentTarget.getAttribute(component.config.attrib.offerRemoveID);
      const tierRemoveID = e.currentTarget.getAttribute(component.config.attrib.tierRemoveID);
      const freeGiftId = document.querySelector(`[data-gift-id="${skuRemoveID}${offerRemoveID}${tierRemoveID}"]`);
      const onReload = true;
      const isComplex = freeGiftId.classList.contains(component.config.classNames.hasComplexClass);

      component.removeUniqueItem(skuRemoveID, offerRemoveID, tierRemoveID, freeGiftId, onReload, isComplex);
    };

    const _removeUniqueItem = (skuRemoveID, offerRemoveID, tierRemoveID, freeGiftId, onReload, isComplex) => {
      if (onReload) {
        component.element = document.querySelector(`[data-tier-id="${offerRemoveID}${tierRemoveID}"]`);
        component.getSelectedElement(component.element);
      }

      if (isComplex) {
        component.masterSKU = freeGiftId.getAttribute(component.config.attrib.masterID);
        component.isComplexItem = true;
        component.isSimpleItem = false;
        component.replacedDropdowns = freeGiftId.parentElement.querySelectorAll(component.config.variationOption.dropdownVariation);
        component.optionDetailsDesc = freeGiftId.querySelector(component.config.selectors.optionsDetailDesc);
        component.complexSelectText = freeGiftId.querySelector(component.config.selectors.complexSelectText);
        component.resetComplexDropdowns(component.replacedDropdowns);
      }

      component.removeBasketItem = document.getElementById(skuRemoveID + offerRemoveID + tierRemoveID);
      component.element.selectedFreeProducts = component.element.selectedFreeProducts > 0 ? component.element.selectedFreeProducts - 1 : 0;
      freeGiftId.classList.remove(component.config.classNames.freeProductItemSelected);
      component.radioBehaviour = false;
      component.updateSelectedProductsDom();
      component.ajaxBasketRemoveFreeGift(skuRemoveID, offerRemoveID, tierRemoveID, 'basketRemove');
    };

    const _bindOpen = () => {
      const sysToggleOpen = component.element.querySelector(component.config.selectors.toggleOpen);
      sysToggleOpen.addEventListener('click', component.toggleOpenSamples);
    };

    const _attachListeners = () => {
      Array.from(component.productBlocks).map(element => {
        element.addEventListener('click', (e) => {
          e.preventDefault();
          component.selectItem(e.currentTarget);
        }, false);
      });
    };

    const _complexAttachListeners = () => {
      component.complexItemSelection();
      Array.from(component.productBlocksComplex).map(el => el.addEventListener('click', component.openComplexItem, false));
    };

    const _openComplexItem = (e) => {
      e.preventDefault();

      const currentTarget = e.currentTarget.parentElement;
      const skuId = e.currentTarget.getAttribute(component.config.attrib.skuID);
      const offerId = e.currentTarget.getAttribute(component.config.attrib.offerID);
      const tierId = e.currentTarget.getAttribute(component.config.attrib.tierID);
      const masterId = e.currentTarget.getAttribute(component.config.attrib.masterID);
      const dropdown = currentTarget.querySelector(component.config.selectors.variationDropdown);

      component.element = document.querySelector(`[data-tier-id="${offerId}${tierId}"]`);
      component.getSelectedElement(component.element);
      component.masterSKU = masterId;
      component.offerId = offerId;

      component.replacedDropdowns = currentTarget.querySelectorAll(component.config.variationOption.dropdownVariation);
      component.optionDetailsDesc = currentTarget.querySelector(component.config.selectors.optionsDetailDesc);
      component.complexSelectText = currentTarget.querySelector(component.config.selectors.complexSelectText);

      if (e.currentTarget.classList.contains(component.config.classNames.freeProductItemSelected)) {
        component.radioBehaviour = false;
        component.isComplexItem = true;
        component.isSimpleItem = false;
        component.removeBasketItem = document.getElementById(skuId + offerId + tierId);
        component.element.selectedFreeProducts = component.element.selectedFreeProducts > 0 ? component.element.selectedFreeProducts - 1 : 0;
        e.currentTarget.classList.remove(component.config.classNames.freeProductItemSelected);
        component.updateImage(masterId, masterId);
        component.ajaxBasketRemoveFreeGift(skuId, offerId, tierId, 'basketRemove');
      } else {
        if (currentTarget.classList.contains(component.config.classNames.complexItemShow)) {
          currentTarget.classList.remove(component.config.classNames.complexItemShow);
          dropdown.classList.remove(component.config.classNames.variationDropdownShow);
          app.publish('tracking/record', 'freeProductSelection', 'Collapse Complex Item', 'Offer Id', offerId);
        } else {
          currentTarget.classList.add(component.config.classNames.complexItemShow);
          dropdown.classList.add(component.config.classNames.variationDropdownShow);
          app.publish('tracking/record', 'freeProductSelection', 'Expand Complex Item', 'Offer Id', offerId);
        }
      }
      component.updateSelectedProductsDom();
    };

    const _checkLastItemIsComplex = (selectedItem) => {
      const isComplex = selectedItem.classList.contains(component.config.classNames.hasComplexClass);
      return !isComplex;
    };

    const _complexItemSelection = () => {
      const variations = component.element.querySelectorAll(component.config.variationOption.variationContainer);

      Array.from(variations).map(el => {
        const variationDropdowns = el.querySelectorAll(component.config.variationOption.dropdownVariation);
        const masterSKU = el.getAttribute(component.config.attrib.variationMasterSKU);

        Array.from(variationDropdowns).map(el => el.addEventListener('change', component.variationsChange.bind(el, variationDropdowns, masterSKU)));
      });
    };

    const _variationsChange = (variationDropdown, masterSKU) => {
      const numberOfDropdowns = variationDropdown.length;
      const selectedDataObj = component.constructPostData(variationDropdown);
      const selectedData = JSON.stringify(selectedDataObj);
      const readytoAdd = selectedDataObj.selected === numberOfDropdowns;
      app.ajax.post({
        url: '/' + masterSKU + '.variations?stringTemplatePath=components/freeProductSelection/partials/freeProductVariations&preSelectOptions=false&ignoreLinkedSkus=true',
        send: selectedData,
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json'
        },
        success: (data) => component.variationsChangeSuccess(data, masterSKU, readytoAdd, selectedDataObj.selected),
        error: () => component.variationsChangeError(masterSKU)
      });
    };

    const _variationsChangeSuccess = (response, masterSKU, readytoAdd, totalSelected) => {
      let newContainer = document.createElement('html');
      newContainer.innerHTML = response;
      let replaceElem = component.element.querySelector(`[data-master-id="${masterSKU}"]`);
      let siblingElem = replaceElem.parentElement.querySelector(component.config.selectors.freeProductItemProductBlockComplex);
      let newElem = newContainer.querySelector(component.config.variationOption.variationReplace);
      let oldElem = replaceElem.querySelector(component.config.variationOption.variationReplace);
      component.masterSKU = masterSKU;
      component.complexOfferId = replaceElem.getAttribute('data-variation-offer');
      component.complexTierId = replaceElem.getAttribute('data-variation-tier');
      component.replacedDropdowns = newElem.querySelectorAll(component.config.variationOption.dropdownVariation);

      if (totalSelected !== 0 && !readytoAdd) {
        newElem.classList.add(component.config.classNames.variationDropdownShow);
      }

      replaceElem.removeChild(oldElem);
      replaceElem.appendChild(newElem);
      component.attachChangeDropdown(component.replacedDropdowns, component.masterSKU);

      const childSku = String(newElem.getAttribute('data-child-id'));

      if (readytoAdd) {
        component.readyToAddComplex(childSku, siblingElem);
        component.updateImage(masterSKU, childSku);
        const targetGift = component.element.querySelector(`[data-master-sku="${masterSKU}"]`);
        targetGift && targetGift.focus();
      }
    };

    const _variationsChangeError = (sku) => {
      $console.error('ERROR: Could not retrieve variation data for product: ', sku);
    };

    const _updateImage = (masterSKU, childSku) => {
      app.ajax.get({
        url: `/${childSku}.image?imageSize=thumbnail&stringTemplatePath=components/freeProductSelection/partials/freeProductImage`,
        dataType: 'JSON',
        success: (data) => component.updateImageSuccess(data, masterSKU),
        error: () => component.updateImageError(childSku)
      });
    };

    const _updateImageSuccess = (response, masterSKU) => {
      const imageEl = component.element.querySelector(`[data-free-gift-image="${masterSKU}"]`);
      imageEl.innerHTML = response;
    };

    const _updateImageError = (sku) => {
      $console.error('ERROR: Could not update thumbnail image for child SKU: ', sku);
    };

    const _readyToAddComplex = (childSku, siblingElem) => {
      const selectedItem = component.element.querySelector(`.${component.config.classNames.freeProductItemSelected}`);
      const selectedItems = component.element.querySelectorAll(`.${component.config.classNames.freeProductItemSelected}`);
      siblingElem.setAttribute('data-gift-id', childSku + component.complexOfferId + component.complexTierId);
      siblingElem.setAttribute('data-sku', childSku);

      component.complexOptionStringEl(childSku, component.complexOfferId, component.complexTierId);
      component.isComplexItem = true;
      component.isSimpleItem = false;

      component.selectedSkus = component.getSelectedSKUs(selectedItems);

      if (component.element.maxFreeProducts > 1) {
        component.radioBehaviour = false;
        component.element.selectedFreeProducts++;
        component.ajaxBasketAddFreeGift(childSku, component.complexOfferId, component.complexTierId, 'basketAdd');
      } else {
        if (selectedItem && component.productBlockComplex !== selectedItem) {
          component.isSimpleItem = component.checkLastItemIsComplex(selectedItem);
          selectedItem.classList.remove(component.config.classNames.freeProductItemSelected);
          component.radioBehaviour = true;
          component.radioTarget = { skuId: childSku, offerId: component.complexOfferId, tierId: component.complexTierId };
          component.removeBasketItem = null;
          component.element.selectedFreeProducts = 1;
          if (!component.isSimpleItem) {
            component.replacedDropdowns = selectedItem.parentElement.querySelectorAll(component.config.variationOption.dropdownVariation);
            component.complexOptionStringEl(component.lastOfferSelected.skuId, component.lastOfferSelected.offerId, component.lastOfferSelected.tierId);
            component.resetComplexDropdowns(component.replacedDropdowns);
            component.variationsChange(component.replacedDropdowns, component.lastOfferSelected.masterId);
            component.updateImage(component.lastOfferSelected.masterId, component.lastOfferSelected.masterId);
          }
          component.selectedSkus = [];
          component.ajaxBasketRemoveFreeGift(component.lastOfferSelected.skuId, component.lastOfferSelected.offerId, component.lastOfferSelected.tierId, 'basketRemove');
        }

        if (!component.radioBehaviour) {
          component.lastOfferSelected = { skuId: childSku, offerId: component.complexOfferId, tierId: component.complexTierId };
          component.element.selectedFreeProducts = 1;
          component.ajaxBasketAddFreeGift(childSku, component.complexOfferId, component.complexTierId, 'basketAdd');
        } else {
          component.removeBasketItem = document.getElementById(component.lastOfferSelected.skuId + component.lastOfferSelected.offerId + component.lastOfferSelected.tierId);
          component.removeBasketItem.classList.remove(component.config.classNames.basketItemRemoving);
          component.removeBasketItem.classList.add(component.config.classNames.basketItemRemovingOpacity);
          component.element.selectedFreeProducts = 1;
        }
      }
      component.lastOfferSelected = {
        skuId: childSku,
        offerId: component.complexOfferId,
        tierId: component.complexTierId,
        masterId: component.masterSKU
      };

      component.updateSelectedProductsDom();
    };

    const _complexOptionStringEl = (sku, offer, tier) => {
      component.productBlockComplex = component.element.querySelector(`[data-gift-id="${sku}${offer}${tier}"]`);
      component.optionDetails = component.productBlockComplex.parentElement.querySelectorAll(component.config.selectors.optionsDetail);
      component.optionDetailsDesc = component.productBlockComplex.querySelector(component.config.selectors.optionsDetailDesc);
      component.complexSelectText = component.productBlockComplex.querySelector(component.config.selectors.complexSelectText);
      component.optionDetailsString = component.concatOptionDetails(component.optionDetails).join(', ');
    };

    const _concatOptionDetails = (optionDetails) => {
      const detailString = Array.from(optionDetails).map(el => {
        const getAttrib = el.getAttribute(component.config.attrib.optionDetails);
        return getAttrib;
      });

      return detailString;
    };

    const _resetComplexDropdowns = (replacedDropdowns) => {
      Array.from(replacedDropdowns).map(el => {
        el.selectedIndex = 0;
      });
    };

    const _attachChangeDropdown = (replacedDropdowns, masterSKU) => {
      Array.from(replacedDropdowns).map(el => {
        el.addEventListener('change', component.variationsChange.bind(el, replacedDropdowns, masterSKU))
      });
    };

    const _constructPostData = (variationDropdown) => {
      const postData = {
        selected: 0,
      };

      for (let i = 0; i < variationDropdown.length; i++) {
        const input = variationDropdown[i];
        postData['variation' + (i + 1)] = input.getAttribute(component.config.attrib.variationID);
        postData['option' + (i + 1)] = input.value;
        if (input.value) postData.selected++;
      }

      return postData;
    };

    const _selectItem = (currentTarget) => {
      const skuId = currentTarget.getAttribute(component.config.attrib.skuID);
      const offerId = currentTarget.getAttribute(component.config.attrib.offerID);
      const tierId = currentTarget.getAttribute(component.config.attrib.tierID);
      const masterId = currentTarget.getAttribute(component.config.attrib.masterID);

      component.element = document.querySelector(`[data-tier-id="${offerId}${tierId}"]`);
      component.getSelectedElement(component.element);

      const selectedItem = component.element.querySelector(`.${component.config.classNames.freeProductItemSelected}`);
      const selectedItems = component.element.querySelectorAll(`.${component.config.classNames.freeProductItemSelected}`);

      component.selectedSkus = component.getSelectedSKUs(selectedItems);


      component.target = currentTarget;
      component.isComplexItem = false;
      component.isSimpleItem = true;
      component.offerId = offerId;

      if (component.element.maxFreeProducts > 1) {
        component.radioBehaviour = false;
        if (!component.target.classList.contains(component.config.classNames.freeProductItemSelected)) {
          component.element.selectedFreeProducts = component.element.selectedFreeProducts + 1;
          component.target.classList.add(component.config.classNames.freeProductItemSelected);
          component.ajaxBasketAddFreeGift(skuId, offerId, tierId, 'basketAdd');
        } else {
          component.removeBasketItem = document.getElementById(skuId + offerId + tierId);
          component.element.selectedFreeProducts = component.element.selectedFreeProducts > 0 ? component.element.selectedFreeProducts - 1 : 0;
          component.target.classList.remove(component.config.classNames.freeProductItemSelected);
          component.ajaxBasketRemoveFreeGift(skuId, offerId, tierId, 'basketRemove');
        }
      } else {
        component.radioBehaviour = false;
        if (selectedItem && component.target !== component.selectedItem) {
          component.isSimpleItem = component.checkLastItemIsComplex(selectedItem);
          selectedItem.classList.remove(component.config.classNames.freeProductItemSelected);
          component.radioBehaviour = true;
          component.radioTarget = { skuId, offerId, tierId };
          component.removeBasketItem = null;

          if (!component.isSimpleItem) {
            component.isComplexItem = true;
            component.replacedDropdowns = selectedItem.parentElement.querySelectorAll(component.config.variationOption.dropdownVariation);
            component.complexOptionStringEl(component.lastOfferSelected.skuId, component.lastOfferSelected.offerId, component.lastOfferSelected.tierId);
            component.resetComplexDropdowns(component.replacedDropdowns);
            component.variationsChange(component.replacedDropdowns, component.lastOfferSelected.masterId);
            component.updateImage(component.lastOfferSelected.masterId, component.lastOfferSelected.masterId);
          }
          component.element.selectedFreeProducts = 1;
          component.selectedSkus = [];
          component.ajaxBasketRemoveFreeGift(component.lastOfferSelected.skuId, component.lastOfferSelected.offerId, component.lastOfferSelected.tierId, 'basketRemove');
        }

        if (!component.radioBehaviour) {
          component.checkboxSelect(skuId, offerId, tierId, component.target);
        } else {
          component.removeBasketItem = document.getElementById(component.lastOfferSelected.skuId + component.lastOfferSelected.offerId + component.lastOfferSelected.tierId);
          component.removeBasketItem.classList.remove(component.config.classNames.basketItemRemoving);
          component.removeBasketItem.classList.add(component.config.classNames.basketItemRemovingOpacity);
          component.target.classList.add(component.config.classNames.freeProductItemSelected);
          component.element.selectedFreeProducts = 1;
        }
      }
      component.lastOfferSelected = { skuId, offerId, tierId, masterId };
      component.updateSelectedProductsDom();
    };

    const _checkboxSelect = (skuId, offerId, tierId, target) => {

      if (!target.classList.contains(component.config.classNames.freeProductItemSelected)) {
        target.classList.add(component.config.classNames.freeProductItemSelected);
        component.lastOfferSelected = { skuId, offerId, tierId };
        component.element.selectedFreeProducts = 1;
        component.ajaxBasketAddFreeGift(skuId, offerId, tierId, 'basketAdd');
      } else {
        component.removeBasketItem = document.getElementById(skuId + offerId + tierId);
        component.target.classList.remove(component.config.classNames.freeProductItemSelected);
        component.element.selectedFreeProducts = 0;
        component.ajaxBasketRemoveFreeGift(skuId, offerId, tierId, 'basketRemove');
      }
    };

    const _ajaxBasketAddFreeGift = (productId, offerId, tierId, endpoint) => {

      app.publish('tracking/record', 'freeProductSelection', 'Adding Item', 'Gift Id', `${productId}${offerId}${tierId}`);

      !component.radioBehaviour && component.basketHeader.classList.remove(component.config.classNames.basketHeaderDone);
      component.disableEnableAll('disable');

      const templatePathSelector = document.querySelector('[data-basket-product-template-path]');
      const templatePath = templatePathSelector.getAttribute('data-basket-product-template-path');
      app.ajax.post({
        url: `/${endpoint}.bsk`,
        send: {
          quantity: 1,
          productId,
          offerId,
          tierId,
          currentlySelectedProductIds: component.selectedSkus,
          templatePath
        },
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json'
        },
        success: (data) => component.updateFreeGiftSuccess(data, productId, offerId, tierId),
        error: (data) => component.updateFreeGiftError(data, productId, offerId, tierId)
      });
    };

    const _ajaxBasketRemoveFreeGift = (productId, offerId, tierId, endpoint) => {
      app.publish('tracking/record', 'freeProductSelection', 'Removing Item', 'Gift Id', `${productId}${offerId}${tierId}`);
      if (component.removeBasketItem) {
        component.removeBasketItem.classList.remove(component.config.classNames.basketItemRemoving);
        component.removeBasketItem.classList.add(component.config.classNames.basketItemRemovingOpacity);
      }
      component.disableEnableAll('disable');

      app.ajax.post({
        url: `/${endpoint}.bsk`,
        send: {
          quantity: 1,
          productId,
          offerId,
          tierId
        },
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json'
        },
        success: () => component.removeFreeGiftSuccess(productId, offerId, tierId),
        error: () => component.removeFreeGiftError(productId, offerId, tierId)
      });
    };

    const _updateFreeGiftSuccess = (data, skuID, offerID, tierID) => {
      let wrapper = document.createElement('div');
      component.dataItem = data;
      wrapper.innerHTML = component.dataItem;

      if (component.radioBehaviour) {
        component.removeBasketItem.parentElement.innerHTML = component.dataItem;
      } else {
        component.freeGiftContainer.appendChild(wrapper);
        component.basketHeader.classList.add(component.config.classNames.basketHeaderDone);
      }

      component.bindNewRemove(skuID, offerID, tierID);
      component.disableEnableAll('enable');

      if (component.isComplexItem && !component.isSimpleItem) {
        if (component.complexSelectText) {
          component.productBlockComplex.classList.add(component.config.classNames.freeProductItemSelected);
          component.productBlockComplex.parentElement.classList.remove(component.config.classNames.complexItemShow);
          component.optionDetailsDesc.classList.add(component.config.classNames.optionTextShow);
          component.optionDetailsDesc.innerHTML = component.optionDetailsString;
          component.complexSelectText.classList.add(component.config.classNames.optionTextHide);
          component.resetComplexDropdowns(component.replacedDropdowns);
          component.variationsChange(component.replacedDropdowns, component.masterSKU);
          app.publish('tracking/record', 'freeProductSelection', 'Added Complex Item', 'Gift Id', `${skuID}${offerID}${tierID}`);
        }
      } else {
        app.publish('tracking/record', 'freeProductSelection', 'Added Simple Item', 'Gift Id', `${skuID}${offerID}${tierID}`);
      }
    };

    const _updateFreeGiftError = (data, skuID, offerID, tierID) => {
      const errorCode = data.trim();
      const freeGiftId = component.element.querySelector(`[data-gift-id="${skuID}${offerID}${tierID}"]`);
      const oosProduct = document.querySelector(component.config.selectors.oosProduct);
      const offerExpired = document.querySelector(component.config.selectors.offerExpired);
      const productOverMaxPerOrder = document.querySelector(component.config.selectors.productOverMaxPerOrder);
      const addRemoveError = document.querySelector(component.config.selectors.addGiftError);
      const noMoreOffer = document.querySelector(component.config.selectors.noMoreOffer);

      switch (errorCode) {
        case 'PRODUCT_OOS':
          component.fadeOutError(oosProduct);
          break;
        case 'EXPIRED_OFFER':
          component.fadeOutError(offerExpired);
          break;
        case 'INELIGIBLE_OFFER':
        case 'UPDATED_OFFER':
        case 'INELIGIBLE_TIER':
          component.fadeOutError(noMoreOffer);
          break;
        case 'OVER_MAX_PER_ORDER':
          component.fadeOutError(productOverMaxPerOrder);
          break;
        default:
          component.fadeOutError(addRemoveError);
      }

      freeGiftId.classList.remove(component.config.classNames.freeProductItemSelected);
      component.element.selectedFreeProducts = component.element.selectedFreeProducts > 0 ? component.element.selectedFreeProducts - 1 : 0;

      component.updateSelectedProductsDom();
      component.basketHeader.classList.add(component.config.classNames.basketHeaderDone);
      component.disableEnableAll('enable');

      if (component.isComplexItem && !component.isSimpleItem) {
        component.resetComplexDropdowns(component.replacedDropdowns);
        component.variationsChange(component.replacedDropdowns, component.masterSKU);
      }

      if (component.removeBasketItem && component.radioBehaviour) {
        component.removeBasketItem.parentElement.parentNode.removeChild(component.removeBasketItem.parentElement);
      }
    };

    const _fadeOutError = (el) => {
      el.classList.remove(component.config.classNames.showErrorPopup);
      void el.offsetWidth;
      el.classList.add(component.config.classNames.showErrorPopup);
    };

    const _removeFreeGiftSuccess = (skuID, offerID, tierID) => {
      const removeItem = document.getElementById(skuID + offerID + tierID);
      component.disableEnableAll('enable');

      if (component.isComplexItem && !component.isSimpleItem) {
        if (component.complexSelectText) {
          component.optionDetailsDesc.classList.remove(component.config.classNames.optionTextShow);
          component.optionDetailsDesc.innerHTML = '';
          component.complexSelectText.classList.remove(component.config.classNames.optionTextHide);
          component.resetComplexDropdowns(component.replacedDropdowns);
          component.variationsChange(component.replacedDropdowns, component.masterSKU);
          component.updateImage(component.masterSKU, component.masterSKU);
          app.publish('tracking/record', 'freeProductSelection', 'Removed Complex Item', 'Gift Id', `${skuID}${offerID}${tierID}`);
        }
      } else {
        app.publish('tracking/record', 'freeProductSelection', 'Removed Simple Item', 'Gift Id', `${skuID}${offerID}${tierID}`);
      }

      if (component.radioBehaviour) {
        component.isSimpleItem = !component.isSimpleItem && !component.isComplexItem;

        if (component.isComplexItem) {
          component.complexOptionStringEl(component.radioTarget.skuId, component.radioTarget.offerId, component.radioTarget.tierId);
        }
        component.ajaxBasketAddFreeGift(component.radioTarget.skuId, component.radioTarget.offerId, component.radioTarget.tierId, 'basketAdd');
      } else {
        if (removeItem) {
          removeItem.parentElement.parentNode.removeChild(removeItem.parentElement);
        }
      }
    };

    const _removeFreeGiftError = (skuID, offerID, tierID) => {
      const addRemoveError = document.querySelector(component.config.selectors.removeGiftError);

      if (component.element.maxFreeProducts === 1 && component.radioBehaviour) {
        const removeItemButton = document.querySelector(component.config.selectors.removeItem);
        const oneSkuID = removeItemButton.getAttribute(component.config.attrib.skuRemoveID);
        const oneOfferID = removeItemButton.getAttribute(component.config.attrib.offerRemoveID);
        const oneTierID = removeItemButton.getAttribute(component.config.attrib.tierRemoveID);
        const freeGiftIdRadio = component.element.querySelector(`[data-gift-id="${oneSkuID}${oneOfferID}${oneTierID}"]`);
        const freeGiftLastSelect = component.element.querySelector(`[data-gift-id="${component.lastOfferSelected.skuId}${component.lastOfferSelected.offerId}${component.lastOfferSelected.tierId}"]`);
        freeGiftIdRadio.classList.add(component.config.classNames.freeProductItemSelected);
        freeGiftLastSelect.classList.remove(component.config.classNames.freeProductItemSelected);
        component.basketHeader.classList.add(component.config.classNames.basketHeaderDone);
      } else {
        const freeGiftId = component.element.querySelector(`[data-gift-id="${skuID}${offerID}${tierID}"]`);
        freeGiftId.classList.add(component.config.classNames.freeProductItemSelected);
        component.element.selectedFreeProducts = component.element.selectedFreeProducts + 1;
      }

      if (component.removeBasketItem) {
        component.removeBasketItem.classList.add(component.config.classNames.basketItemRemoving);
        component.removeBasketItem.classList.remove(component.config.classNames.basketItemRemovingOpacity);
      }

      component.disableEnableAll('enable');
      component.fadeOutError(addRemoveError);
      component.updateSelectedProductsDom();
    };

    const _disableEnableAll = (type) => {
      if (component.element.maxFreeProducts === 1) {
        switch (type) {
          case 'disable':
            Array.from(component.productItemCommon).map(el => el.style.pointerEvents = 'none');
            break;
          case 'enable':
            Array.from(component.productItemCommon).map(el => el.style.pointerEvents = 'all');
            break;
        }
      }
    };

    const _greyOutMaxedFilter = () => {
      Array.from(component.productItemCommon).filter(element => !element.classList.contains(component.config.classNames.freeProductItemSelected)).map(nonSelected => {
        component.greyOutMaxed(nonSelected);
      });
    };

    const _alreadySelected = () => {
      component.isComplexItem = true;
      Array.from(component.productBlocksComplex).filter(element => element.classList.contains(component.config.classNames.freeProductItemSelected)).map(selected => {
        const parentEl = selected.parentElement;
        component.replacedDropdowns = parentEl.querySelectorAll(component.config.variationOption.dropdownVariation);
        component.variantEl = parentEl.querySelectorAll(component.config.selectors.optionsDetail);
        component.optionDetailsDesc = parentEl.querySelector(component.config.selectors.optionsDetailDesc);
        component.complexSelectText = parentEl.querySelector(component.config.selectors.complexSelectText);

        if (!component.variantEl.length) {
          return;
        }

        const optionDetailsString = component.concatOptionDetails(component.variantEl).join(', ');
        component.optionDetailsDesc.classList.add(component.config.classNames.optionTextShow);
        component.optionDetailsDesc.innerHTML = optionDetailsString;
        component.complexSelectText.classList.add(component.config.classNames.optionTextHide);
      });
    };

    const _greyOutMaxed = (nonSelected) => {
      if (component.element.selectedFreeProducts >= component.element.maxFreeProducts) {
        nonSelected.classList.add(component.config.classNames.freeProductItemSelectedDisabled);
        if (component.element.maxFreeProducts === 1) {
          nonSelected.classList.add(component.config.classNames.freeProductItemSelectedEnabled);
        }
      } else {
        nonSelected.classList.remove(component.config.classNames.freeProductItemSelectedDisabled);
      }
    };

    const _updateSelectedProductsDom = () => {
      const selectedEl = component.element.querySelector(component.config.selectors.selectedFreeProducts);
      if (!selectedEl) {
        return;
      }
      selectedEl.innerText = component.element.selectedFreeProducts;
      selectedEl.setAttribute('data-current-selected', component.element.selectedFreeProducts);

      app.publish('freeProductSelection/totalSelection');
    };

    const _getSelectedSKUs = (selectedItems) => {
      if (selectedItems.length) {
        return Array.from(selectedItems).map(el => {
          return el.getAttribute('data-sku');
        });
      } else {
        return [];
      }
    };

    component.config = _config;
    component.init = _init;
    component.getSelectedElement = _getSelectedElement;
    component.observeSelected = _observeSelected;
    component.alreadySelected = _alreadySelected;
    component.bindNewRemove = _bindNewRemove;
    component.removeItem = _removeItem;
    component.removeUniqueItem = _removeUniqueItem;
    component.bindOpen = _bindOpen;
    component.attachListeners = _attachListeners;
    component.complexAttachListeners = _complexAttachListeners;
    component.complexItemSelection = _complexItemSelection;
    component.checkLastItemIsComplex = _checkLastItemIsComplex;
    component.variationsChange = _variationsChange;
    component.variationsChangeSuccess = _variationsChangeSuccess;
    component.variationsChangeError = _variationsChangeError;
    component.readyToAddComplex = _readyToAddComplex;
    component.updateImage = _updateImage;
    component.updateImageSuccess = _updateImageSuccess;
    component.updateImageError = _updateImageError;
    component.concatOptionDetails = _concatOptionDetails;
    component.complexOptionStringEl = _complexOptionStringEl;
    component.resetComplexDropdowns = _resetComplexDropdowns;
    component.attachChangeDropdown = _attachChangeDropdown;
    component.constructPostData = _constructPostData;
    component.openComplexItem = _openComplexItem;
    component.selectItem = _selectItem;
    component.checkboxSelect = _checkboxSelect;
    component.disableEnableAll = _disableEnableAll;
    component.ajaxBasketAddFreeGift = _ajaxBasketAddFreeGift;
    component.ajaxBasketRemoveFreeGift = _ajaxBasketRemoveFreeGift;
    component.updateFreeGiftSuccess = _updateFreeGiftSuccess;
    component.updateFreeGiftError = _updateFreeGiftError;
    component.removeFreeGiftSuccess = _removeFreeGiftSuccess;
    component.removeFreeGiftError = _removeFreeGiftError;
    component.fadeOutError = _fadeOutError;
    component.greyOutMaxedFilter = _greyOutMaxedFilter;
    component.greyOutMaxed = _greyOutMaxed;
    component.updateSelectedProductsDom = _updateSelectedProductsDom;
    component.getSelectedSKUs = _getSelectedSKUs;

    return component;
  }

  return freeProductSelection;

});

